<template>
  <div id="app-container" style="height: 100%;">
    <keep-alive>
      <component :is="currentVersionTemplate" ref="app" />
    </keep-alive>
    <div class="gc-highlight-element">
      <div class="gc-highlight-element__wrapper">
        <div class="gc-highlight-element__content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import product from 'app/api/product'

import storeMain from './store/main'
import storeUi from './store/ui'
import storeCart from './store/cart'
import storeDebug from './store/debug'
import storeCard from './store/card'
import storePay from './store/pay'
import storeRegion from './store/region'

import { currency, currencyCode } from './modules/constants'

import { addStat } from 'app/modules/analytics'

import widget from './api/widget'

import { hexToHSL, isSupportedStorage } from './modules/utils'

let options = {}

const defaultVersion = '6'

export default {
  data() {
    return {
      noConfig: false,
      currentVersionTemplate: null
    }
  },
  components: {
    'App-6': require('./versions/6/App').default,
  },
  created: async function () {
    const app = this

    app.$store.registerModule('main', storeMain)
    app.$store.registerModule('ui', storeUi)
    app.$store.registerModule('cart', storeCart)
    app.$store.registerModule('debug', storeDebug)
    app.$store.registerModule('card', storeCard)
    app.$store.registerModule('pay', storePay)
    app.$store.registerModule('region', storeRegion)

    // Логгер
    if (isSupportedStorage(() => localStorage) && localStorage.getItem('debug')) {
      app.$store.dispatch('debug/enableDebug')
    }
    Vue.$logger = {}

    Object.keys(Vue.$log).map((level) => {
      Vue.$logger[level] = function () {
        app.$store.dispatch('debug/addToLog', { time: new Date(), level: level, message: arguments[0] })
        Vue.$log[level].apply(app, arguments)
      }
    })
    Vue.$logger.debug('Site: ' + location.protocol + '//' + location.host)
    Vue.$logger.debug('User agent: ' + navigator.userAgent)
  },
  mounted: async function () {
    const app = this

    const params = Object.fromEntries(new URLSearchParams(location.search))

    options = { ...params, ...options }

    try {
      if (typeof options.config !== 'undefined' && options.config !== '') {
        const responseParams = await widget.getParamsByUrl(options.config)
        options = { ...responseParams, ...options }
      } else if (typeof options.key !== 'undefined' && options.key !== '') {
        const responseParams = await widget.getParamsByKey(options.key)
        options = { ...responseParams, ...options }
      } else if (typeof options.code !== 'undefined' && options.code !== '') {
        const responseParams = await widget.getParamsByCode(options.code)
        options = { ...responseParams, ...options }
      }
    } catch (e) {
      options = {}
    }

    if (typeof options.productId === 'undefined' || parseInt(options.productId) <= 0) {
      this.noConfig = true
    }

    if (typeof options.version === 'undefined') {
      options.version = defaultVersion
    }

    if (options.locale && options.locale !== 'underfined') {
      options.locale = options.locale.substr(0, 2)
    }

    if (typeof options.color !== 'undefined' && options.color !== '') {
      if (!options.color.startsWith('#')) {
        options.color = '#' + options.color
      }

      [options.colorHue, options.colorSaturation, options.colorLightness] = hexToHSL(options.color)

      options.colorHue = options.colorHue * 360
      options.colorSaturation = options.colorSaturation * 100 + '%'
      options.colorLightness = options.colorLightness * 100 + '%'
    } else if (typeof options.colorHue === 'undefined') {
      [options.colorHue, options.colorSaturation, options.colorLightness] = [24, '100%', '49%']
    }

    if (typeof options.colorText !== 'undefined' && options.colorText !== '') {
      if (!options.colorText.startsWith('#')) {
        options.colorText = '#' + options.colorText
      }
    }

    if (options.orderId) {
      app.$store.dispatch('main/setOrderId', parseInt(options.orderId))
    }

    if (!this.noConfig) {
      if (typeof options.isDemo !== 'undefined') {
        if (typeof options.isDemo !== 'boolean') {
          options.isDemo = parseInt(options.isDemo) !== 0
        }
      }

      // Основные параметры приложения
      app.$store.dispatch('main/setKey', options.key)
      app.$store.dispatch('main/setCode', options.code)
      app.$store.dispatch('main/setProductId', parseInt(options.productId))
      app.$store.dispatch('main/setClientId', options.clientId)
      app.$store.dispatch('main/setDemo', options.isDemo)

      const style = document.createElement('style')
      const styles = options.styles || ''
      style.type = 'text/css'
      style.appendChild(document.createTextNode(styles))

      document.getElementsByTagName('body')[0].appendChild(style)
    }

    if (![15362, 15846].includes(parseInt(options.productId))) {
      app.$store.dispatch('main/setAllowVideo', options.allowVideo || true)
    }

    app.$store.dispatch('main/setVersion', options.version)
    app.$store.dispatch('main/setLocale', options.locale)
    app.$store.dispatch('main/setOptions', options)

    app.$store.dispatch('main/setApiUrl', process.env.VUE_APP_WIDGET_API_URL)

    if (typeof options.devmode !== 'undefined' && options.devmode === 'dev') {
      app.$store.dispatch('main/setDevMode', true)
    }

    if (typeof options.recipientFields !== 'undefined') {
      app.$store.dispatch('main/setRecipientFields', options.recipientFields)
    }

    if (typeof options.headerLink !== 'undefined' && options.headerLink) {
      app.$store.dispatch('main/setHeaderLink', options.headerLink)
    }

    this.afterMounted.apply(this)
  },
  watch: {
    '$store.state.main.version': function () {
      if (this.$store.state.main.version) {
        this.currentVersionTemplate = 'App-' + this.$store.state.main.version
      }
    },
    '$store.state.main.productId': function () {
      const app = this

      /* if (typeof this.beforeCreated === 'function') {
        this.beforeCreated()
      } */

      if (typeof this.$store.state.main.productId !== 'undefined' && parseInt(this.$store.state.main.productId) > 0) {
        product.get(this.$store.state.main.productId, this.$store.state.main.isDemo).then(response => {
          if (response.status === 'ok' && (typeof response.error === 'undefined' || response.error !== true)) {
            this.error = ''
            const face_multiplier = Number(options.face_multiplier )|| response.data.properties.face_multiplier

            this.$store.dispatch('main/setProduct', { ...response.data, properties: {...response.data.properties, face_multiplier }})

            this.$store.dispatch('card/setProductId', this.$store.state.main.product.id)

            if (typeof options.checkBalance === 'undefined') {
              if (this.$store.state.main.product.properties.is_merchant_cert !== 'undefined') {
                this.$store.dispatch('ui/setCheckBalance', !this.$store.state.main.product.properties.is_merchant_cert)
              }
            } else {
              if (options.checkBalance === 'disabled') {
                this.$store.dispatch('ui/setCheckBalance', false)
              }
            }

            if (typeof options.corporateOrder !== 'undefined') {
              if (options.corporateOrder === 'enabled') {
                this.$store.dispatch('ui/setCorporateOrder', true)
              } else if (options.corporateOrder === 'disabled') {
                this.$store.dispatch('ui/setCorporateOrder', false)
              }
            }

            this.setTheme(options)
            this.setCssVariables(options)

            if (this.$store.state.main.product.images.length > 0) {
              this.$store.dispatch('card/setCardImage', this.$store.state.main.product.images[0])
            }

            // Filtered faces
            let productFaces = []

            if (typeof this.$store.state.main.product.properties !== 'undefined' && typeof this.$store.state.main.product.properties.faces !== 'undefined') {
              productFaces = this.$store.state.main.product.properties.faces
            }

            productFaces = productFaces.sort((a, b) => a - b)

            if (typeof this.$store.state.main.options.faces === 'object' && this.$store.state.main.options.faces.length > 0) {
              productFaces = productFaces.filter(face => this.$store.state.main.options.faces.includes(face))
            }

            this.$store.dispatch('main/setFaces', productFaces)

            if (typeof this.$store.state.main.options.maxCartCount === 'number') {
              this.$store.dispatch('main/setMaxCartCount', this.$store.state.main.options.maxCartCount)
            }

            // Установка локали виджета исходя из параметров конфигурации/URL или региона продукта
            if (this.$store.state.main.product && this.$store.state.main.product.region) {
              const productRegionLocale = ['by', 'kz', 'ru'].includes(this.$store.state.main.product.region) ? 'ru' : this.$store.state.main.product.region
              const currentRegion = this.$store.state.main.options.locale || productRegionLocale

              this.$store.dispatch('main/setLocale', currentRegion)
            }

            const params = Object.fromEntries(new URLSearchParams(location.search))

            this.setRegionSettings(this.$store.state.main.product.region)
            this.setCurrency(this.$store.state.main.product.region)

            this.$nextTick(() => {
              if (params.success && params.success === '1') {
                this.$store.dispatch('main/setPreload', false)
                app.$refs.app.$router.push({ name: 'PaySuccess' })
              } else if (params.fail && params.fail === '1') {
                this.$store.dispatch('main/setPreload', false)
                app.$refs.app.$router.push({ name: 'PayFail' })
              } else {
                app.$refs.app.$router.push({ name: 'AddCard' })
              }
            })
          } else {
            this.$store.dispatch('main/setError')
          }
        }).catch((error) => {
          Vue.$logger.debug('AddCard | product.get | ошибка: ', error)
        })
      }
    }
  },
  methods: {
    afterMounted() {
      this.currentVersionTemplate = 'App-' + this.$store.state.main.version
      this.addAnalytics()
    },
    addAnalytics() {
      addStat(options.productId, options.code)
    },
    setRegionSettings(region) {
      if (options && options.macroregion) {
        this.$store.dispatch('region/setMacroregion', options.macroregion)
      } else {
        this.$store.dispatch('region/setMacroregion', region)
      }

      if (typeof this.$store.state.main.options.recipientFields === 'undefined') {
        if (!['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)) {
          this.$store.dispatch('main/setRecipientFields', ['name', 'email'])
        }
      }
    },
    setCurrency(region) {
      if (options && options.currencyCode) {
        this.$store.dispatch('region/setCurrencyCode', options.currencyCode)
      } else {
        let currentCurrencyCode = options.macroregion ? currencyCode[options.macroregion] : currencyCode[region]

        this.$store.dispatch('region/setCurrencyCode', currentCurrencyCode)
      }

      this.$store.dispatch('region/setCurrency', currency[this.$store.state.region.currencyCode])
    },
    setTheme(options) {
      options.colorLightnessHover = parseInt(options.colorLightness) - 3 + '%'
      this.$store.dispatch('main/setColorHue', options.colorHue)
      this.$store.dispatch('main/setColorSaturation', options.colorSaturation)
      this.$store.dispatch('main/setColorLightness', options.colorLightness)
      this.$store.dispatch('main/setColorLightnessHover', options.colorLightnessHover)

      if (options.theme) {
        this.$store.dispatch('main/setThemeBackground', options.theme.background)
        this.$store.dispatch('main/setThemeBackgroundWidget', options.theme.backgroundWidget)

        this.$store.dispatch('main/setThemePrimaryBackgroundComponent', options.theme.components.primaryBackground)
        this.$store.dispatch('main/setThemePrimaryTextColorComponent', options.theme.components.primaryText)

        this.$store.dispatch('main/setThemeSecondaryBackgroundComponent', options.theme.components.secondaryBackground)
        this.$store.dispatch('main/setThemeSecondaryTextColorComponent', options.theme.components.secondaryText)

        this.$store.dispatch('main/setThemeReservePrimaryBackgroundComponent', options.theme.components.reservePrimaryBackground)

        this.$store.dispatch('main/setThemeSvgIconBackground', options.theme.svg.fill)
        this.$store.dispatch('main/setThemeSvgIconContent', options.theme.svg.content)
      }

      if (options.color) {
        this.$store.dispatch('main/setThemePrimaryBackgroundComponent', options.color)
        this.$store.dispatch('main/setThemeSecondaryBackgroundComponent', options.color)
        this.$store.dispatch('main/setThemeReservePrimaryBackgroundComponent', options.color)
        this.$store.dispatch('main/setThemeSvgIconBackground', options.color)
        this.$store.dispatch('main/setThemeSvgIconContent', options.color)

        this.$store.dispatch('main/setThemeBackground', '#E9EBEC')
        this.$store.dispatch('main/setThemeBackgroundWidget', '#F6F6F6')

        // TODO: default white for fonts and svg content
        this.$store.dispatch('main/setThemePrimaryTextColorComponent', '#ffffff')
        this.$store.dispatch('main/setThemeSvgIconContent', '#ffffff')
      }

      if (options.colorText) {
        this.$store.dispatch('main/setThemePrimaryTextColorComponent', options.colorText)
        this.$store.dispatch('main/setThemeSvgIconContent', options.colorText)
      }

      if (options.theme && !options.theme.background) {
        this.$store.dispatch('main/setThemeBackground', '#E9EBEC')
      }

      if (options.theme && !options.theme.backgroundWidget) {
        this.$store.dispatch('main/setThemeBackgroundWidget', '#F6F6F6')
      }


      if (!options.theme && !options.color) {
        this.setDefaultTheme(options)
      }
    },
    setCssVariables(options) {
      if (options.version && options.version === '6') {
        document.documentElement.style.setProperty('--theme-background', this.$store.state.main.themeBackground)
        document.documentElement.style.setProperty('--theme-background-widget', this.$store.state.main.themeBackgroundWidget)

        document.documentElement.style.setProperty('--theme-primary-background-component', this.$store.state.main.themePrimaryBackgroundComponent)
        document.documentElement.style.setProperty('--theme-primary-text-color-component', this.$store.state.main.themePrimaryTextColorComponent)

        document.documentElement.style.setProperty('--theme-secondary-background-component', this.$store.state.main.themeSecondaryBackgroundComponent)
        document.documentElement.style.setProperty('--theme-secondary-text-component', this.$store.state.main.setThemeSecondaryTextColorComponent)

        document.documentElement.style.setProperty('--theme-reserve-primary-background-component', this.$store.state.main.themeReservePrimaryBackgroundComponent)

        document.documentElement.style.setProperty('--theme-svg-icon-background', this.$store.state.main.themeSvgIconBackground)
        document.documentElement.style.setProperty('--theme-svg-icon-content', this.$store.state.main.themeSvgIconContent)
      } else {
        this.setOldCssVars()
      }
    },
    setDefaultTheme(options) {
      if (!['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)) {
        options.theme = {
          background: '#E9EBEC',
          backgroundWidget: '#F6F6F6',
          components: {
            primaryBackground: 'linear-gradient(117.59deg, #FFDF39 -1.03%, #F7C516 51.21%, #FFDF39 107.6%)',
            primaryText: '#0E1E0E',
            secondaryBackground: '#F6F6F6',
            secondaryText: '#0E1E0E',
            reservePrimaryBackground: '#FFDF39'
          },
          svg: {
            fill: '#FFDF39',
            content: '#FFFFFF'
          }
        }
      } else {
        options.theme = {
          background: '#E9EBEC',
          backgroundWidget: '#F6F6F6',
          components: {
            primaryBackground: 'linear-gradient(117.59deg, #FF944D -1.03%, #FA6400 51.21%, #FF944D 107.6%)',
            primaryText: '#FFFFFF',
            secondaryBackground: '#F6F6F6',
            secondaryText: '#0B132A',
            reservePrimaryBackground: '#FA6400'
          },
          svg: {
            fill: '#FA6400',
            content: '#FFFFFF'
          }
        }
      }

      this.$store.dispatch('main/setThemeBackground', options.theme.background)
      this.$store.dispatch('main/setThemeBackgroundWidget', options.theme.backgroundWidget)

      this.$store.dispatch('main/setThemePrimaryBackgroundComponent', options.theme.components.primaryBackground)
      this.$store.dispatch('main/setThemePrimaryTextColorComponent', options.theme.components.primaryText)

      this.$store.dispatch('main/setThemeSecondaryBackgroundComponent', options.theme.components.secondaryBackground)
      this.$store.dispatch('main/setThemeSecondaryTextColorComponent', options.theme.components.secondaryText)

      this.$store.dispatch('main/setThemeReservePrimaryBackgroundComponent', options.theme.components.reservePrimaryBackground)

      this.$store.dispatch('main/setThemeSvgIconContent', options.theme.components.reservePrimaryBackground)

      this.$store.dispatch('main/setThemeSvgIconBackground', options.theme.svg.fill)
      this.$store.dispatch('main/setThemeSvgIconContent', options.theme.svg.content)
    },
    setOldCssVars() {
      document.documentElement.style.setProperty('--color-main-hue', this.$store.state.main.colorHue)
      document.documentElement.style.setProperty('--color-main-saturation', this.$store.state.main.colorSaturation)
      document.documentElement.style.setProperty('--color-main-lightness', this.$store.state.main.colorLightness)
      document.documentElement.style.setProperty('--color-main-lightness-hover', this.$store.state.main.colorLightnessHover)
    }
  },
}
</script>

<style lang="scss" scoped>
.gc-highlight-element {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  user-select: none;
  pointer-events: none;
  transition: 0.4s;

  @include mobile {
    display: none;
  }

  &__wrapper {
    width: 100%;
    min-width: rem(300);
    max-width: rem(860);
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
  }

  &__content {
    padding: 0 rem(20);

    &::v-deep {
      .basket-agree {
        padding: 0 rem(20);
        pointer-events: all;
      }

      .basket-agree__text {
        background-color: #fff;
        box-shadow: -6px -2px 2px 12px #fff;
        border-radius: 4px;
      }
    }
  }

  &--visible {
    opacity: 1;
    transition: 0.3s;
  }
}
</style>
