import Vue from 'vue'
import Vuex from 'vuex'

import App from './App'

import VueLogger from 'vuejs-logger'

import VueGtm from 'vue-gtm'

// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'


const objectToValuesPolyfill = (object) => {
  Object.keys(object).map(key => object[key])
}
Object.values = Object.values || objectToValuesPolyfill

Vue.use(Vuex)
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: false,
  showMethodName: false,
  separator: '|',
  showConsoleColors: true
})


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)

import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import VModal from 'vue-js-modal'
Vue.use(VModal, {
  dynamicDefaults: {
    clickToClose: true
  }
})

import { vfmPlugin } from 'vue-final-modal'
Vue.use(vfmPlugin)

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtm, {
    id: ['GTM-WDJDHSM'],
    enabled: true,
    debug: false,
    loadScript: true
  })

  // Sentry.init({
  //   dsn: 'https://d6c1513d73d6415d8e066f01527adef5@sentry.io/1483227',
  //   integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  // })

  /* const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = false
  script.src = '/stat.js'

  document.getElementsByTagName('head')[0].appendChild(script) */
}

Vue.config.productionTip = false


import createPersistedState from 'vuex-persistedstate'
import { isSupportedStorage } from './modules/utils'

if (isSupportedStorage(() => localStorage)) {
  const params = Object.fromEntries(new URLSearchParams(location.search))
  const stateKey = params.productId || params.code || params.key
  const dataState = createPersistedState({
    key: 'widget_state_' + stateKey,
    paths: ['cart']
  })
  App.store = new Vuex.Store(
    {
      plugins: [ dataState ]
    }
  )
} else {
  App.store = new Vuex.Store()
}

new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
})
